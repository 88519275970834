.tabs {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 3fr auto;
    gap: 10px;
    align-items: center;
    margin: auto;
}

.tabs.hidden {
    visibility: hidden;
    pointer-events: none;
}

.tabs .slots-tabs-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .2s opacity;
    will-change: opacity;
    height: 32px;
    width: 32px;
    touch-action: manipulation;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.tabs .slots-tabs-navigation:first-child {
    grid-column: 1;
}

.tabs .slots-tabs-navigation:last-child {
    grid-column: 3;
}

.tabs .slots-tabs-navigation.visible {
    pointer-events: auto;
    opacity: 1;
}

.tabs .available-days-list-wrapper {
    display: flex;
    grid-column: 2;
    overflow: hidden;
}

.tabs .available-days-list-wrapper .available-days-list {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: 20%;
    align-items: flex-end;
    width: 100%;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper {
    padding: 0 0 2px 0;
    display: flex;
    position: relative;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title {
    text-align: center;
    padding: 0 0 4px 0;
    position: relative;
    height: 100%;
    width: 100%;
    cursor: pointer;
    align-items: center;
    min-width: 0;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    left: 0;
    transition: .14s opacity;
    opacity: 0;
    border-bottom: 2px solid var(--p-action-secondary-depressed);
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title:hover:not(.selected):before {
    opacity: .5;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title.selected:before {
    opacity: 1;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title .date-tile-content {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title .date-tile-content .day-name {
    color: #484848;
    -webkit-text-fill-color: #484848;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title .date-tile-content .day-tab-item {
    color: #484848;
    -webkit-text-fill-color: #484848;
    font-size: 14px;
    font-weight: 500;
}

.tabs .available-days-list-wrapper .available-days-list .date-tile-wrapper .date-title .selected-tag {
    padding: 0 4px;
}

@media (max-width: 479px) {
    .tabs {
        gap: 0px;
    }

    .tabs .slots-tabs-navigation {
        height: 30px;
        width: 30px;
    }

    .tabs .available-days-list-wrapper .available-days-list {
        grid-auto-columns: 33.3333333%;
    }

    .tabs .available-days-list-wrapper .available-days-list .date-title {
        text-align: center;
    }

    .tabs .available-days-list-wrapper .available-days-list .date-title .date-tile-content .day-name {
        font-size: 14px;
    }

    .tabs .available-days-list-wrapper .available-days-list .date-title .date-tile-content .day-tab-item {
        font-size: 12px;
    }

    .tabs .available-days-list-wrapper .available-days-list .date-title .selected-tag {
        width: 90%;
    }
}
