.welcome-message-container-wrapper {
    padding: 0 3em 0 3em;
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.welcome-message-container-wrapper .welcome-message-container {
    display: flex;
    flex-flow: column;
}

.welcome-message-container-wrapper .welcome-message-container .primary-text.header-text {
    font-size: 20px;
    font-weight: 600;
}

.welcome-message-container-wrapper .welcome-message-container .secondary-text {
    margin-top: 2ch;
}
