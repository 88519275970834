.event-editor-btns-group .Polaris-ButtonGroup--segmented {
    flex-wrap: wrap;
}

.event-editor-btn-icon-wrapper  {
    height: 0.6rem;
    width: 0.6rem;
    overflow: hidden;
    position: absolute;
    display: block;
    top: 0.35rem;
    left: -0.4rem;
}

.event-editor-btn-icon-wrapper .Polaris-Icon {
    opacity: 0;
    transform: translateY(-100%);
    transition: .2s;
    will-change: opacity, transform;
    height: 1rem;
    width: 1rem;
}

.event-editor-btn-icon-wrapper.valid .Polaris-Icon{
    opacity: 1;
    transform: translateY(0);
}
