.custom-search-container {
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
}

.search-field-wrapper {
    overflow: hidden;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
}

.search-field-wrapper .search-field {
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: .2s transform ease-in-out;
    will-change: transform;
}

.search-field-wrapper.active .search-field {
    transform: translate3d(0, 0, 0);
}

.list-wrapper {
    padding: .8rem 0;
}

.list-wrapper .Polaris-DataTable__Cell {
    padding: 0;
}

.list-wrapper .table-section {
    cursor: pointer;
    padding: 1.6rem;
    height: 100%;
}

.list-wrapper .Polaris-DataTable__Table {
    height: 1px;
}

.list-wrapper .table-section.disabled {
    opacity: .4;
}

.list-wrapper .list-item {
    padding: 1rem 0.8rem;
    position: relative;
    transition: .2s background;
}

.list-wrapper .list-item:not(:last-of-type) {
    border-bottom: 1px solid var(--p-divider);
}

.list-wrapper .list-item.disabled {
    pointer-events: none;
    opacity: .4;
}

.list-wrapper .list-item:not(.loading) {
    cursor: pointer;
}

.list-wrapper .list-item.loading {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 1ch;
}

.list-wrapper .list-item:not(.loading):hover {
    background: var(--p-surface-hovered);
}

.list-wrapper .list-item .date-time span {
    display: block;
    width: 100px;
}
