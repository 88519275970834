.host-data-container {
    display: grid;
    grid-auto-flow: column;
    gap: 0 15px;
    cursor: pointer;
    padding: .4em 1em;
    position: relative;
    width: 100%;
    transition: .14s background, .14s color;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
}
