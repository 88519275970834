.rbc-month-view {
    min-height: 800px;
}

.rbc-month-row {
    height: 50px;
}

.custom-toolbar-container {
    margin-bottom: 0 !important;
}

.rbc-event {
    padding: 0;  
    background-color: transparent;
    border-radius: 2px;
}

.rbc-day-slot .rbc-event {
    border: none;
}

.rbc-event.rbc-selected {
    background-color: #6E76F2;  
}

.rbc-event-pending {
    z-index: 1;  
}

.rbc-event-pending .event-wrapper {
    background-color: #ececec;
    border-left: 4px solid var(--color, #6E76F2);
}

.rbc-event-pending .event-wrapper:before {
    content: '';
    opacity: .2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(135deg, #5555554c 5%, var(--color, #6E76F2) 5%, var(--color, #6E76F2) 20%, #5555554c 20%, #5555554c 25%, #fff 25%, #fff 50%, #5555554c 50%, #5555554c 55%, var(--color, #6E76F2) 55%, var(--color, #6E76F2) 70%, #5555554c 70%, #5555554c 75%, #fff 75%, #fff 100%);
    background-size: 6px 6px;
}

.rbc-event-pending .event-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .2;
    border-radius: 2px;
    background-image: linear-gradient(135deg, var(--color, #6E76F2) 5%, transparent 5%, transparent 20%, var(--color, #6E76F2) 20%, var(--color, #6E76F2) 25%, transparent 25%, transparent 50%, var(--color, #6E76F2) 50%, var(--color, #6E76F2) 55%, transparent 55%, transparent 70%, var(--color, #6E76F2) 70%, var(--color, #6E76F2) 75%, transparent 75%, transparent 100%);
    background-size: 6px 6px;
}

.rbc-event-pending .event-wrapper strong {
    z-index: 1;
    text-shadow: 0 0 4px var(--shadow);
    color: rgb(32, 34, 35);
}

.rbc-event-completed {
    background-color: white;
    z-index: 1;  
}

.rbc-event-completed .event-wrapper {
    position: relative;
    background-color: #ececec40;
    border-left: 4px solid var(--color, #6E76F2);
}

.rbc-event-completed .event-wrapper:after {
    content: '';
    opacity: .4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color, #6E76F2);
    z-index: -1;
}

.rbc-event-completed .event-wrapper strong {
    color: rgb(32, 34, 35);
}

.rbc-event-deleted {
    background-color: white;
    z-index: 1;
    opacity: .4;
}

.rbc-event-deleted .event-wrapper {
    position: relative;
    background-color: white;
    border-left: 4px solid var(--color, #6E76F2);
}

.rbc-event-deleted .event-wrapper:after {
    content: '';
    opacity: .4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: -1;
}

.rbc-event-deleted .event-wrapper strong {
    color: grey;
}

.rbc-event-assigned {
    z-index: 1;  
}

.rbc-event-assigned .event-wrapper {
    border: 1px solid var(--color, #6E76F2);
    background-color: var(--color, #6E76F2);
}

.rbc-event-slot {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
}

.rbc-day-slot .rbc-background-event {
    width: calc(100% + 10px);
}

.rbc-event-slot:before {
    content: '';
    position: absolute;
    background-color: #6e77f21b;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
}

.rbc-event-block .event-wrapper {
    background-color: #cdcdcd;
    z-index: 1;
}

.rbc-event-block .event-wrapper:before {
    --color: #6a6a6a;
    --color2: rgb(94 94 94);
    content: '';
    opacity: .1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(135deg, var(--color2) 5%, var(--color, #6E76F2) 5%, var(--color, #6E76F2) 20%, var(--color2) 20%, var(--color2) 25%, transparent 25%, transparent 50%, var(--color2) 50%, var(--color2) 55%, var(--color, #6E76F2) 55%, var(--color, #6E76F2) 70%, var(--color2) 70%, var(--color2) 75%, transparent 75%, transparent 100%);
    background-size: 6px 6px;
}

.rbc-event-block .event-wrapper .event-wrapper-content strong {
    color: #727272;
}

.rbc-event-completed.rbc-selected {
    background-color: white;
    color: #6E76F2;    
}

.rbc-event-available-window {
    background-color: #FFBE88;
}

.rbc-day-slot .rbc-event {
    border-color: #ffffff;
    border: 1px solid #fff;
}

.rbc-day-slot .rbc-event-available-window {
    border-color: #FFBE88;
}

.rbc-event-label {
    display: none;
}

.rbc-current-time-indicator {
    height: 2px;
    background-color:#4D53AD;
}

.rbc-allday-cell {
    display: none;
}

.slot-shop-closed {
    background-color:rgba(0,0,0,0.05);
}

.day-shop-closed {
    background-color:rgba(0,0,0,0.05);
}

.multiple-slot-event {
    background-color: #C8E4E050;
}

.custom-toolbar-container {
    margin-bottom: 20px;
}

.custom-event-wrapper-container.blue .rbc-event {
    background-color: var(--event-color);
    border-color: var(--event-color);
}

.host-calendar-controls {
    border-top: 1px solid #DDD;
    padding: 12px 0;
}

.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
    background-color: white !important;
    opacity: 1.0 !important;
}

.rbc-timeslot-group {
    min-height: 90px;
}

.rbc-today {
    background-color: transparent;
}

.rbc-header.rbc-today {
    background-color: #eaf6ff;
}

.rbc-event {
    height: 32px;
}

.rbc-event-pending,
.rbc-event-completed,
.rbc-event-assigned,
.rbc-event-block {
    position: relative;
}

.rbc-event-pending,
.rbc-event-completed,
.rbc-event-assigned,
.rbc-event-block {
    z-index: 1;
}

.event-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: flex-start;
    padding: 2px 4px;
    width: 100%;
    position: absolute;
    height: 100%;
}

.event-wrapper .event-wrapper-content {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 4px;
}

.event-wrapper .event-wrapper-content .Polaris-Avatar {
    min-width: 20px;
    aspect-ratio: 1;
}

.event-wrapper .event-wrapper-content strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} 

.legend-container {
    margin: 1.6rem 0 0 0;
}

.legend-icon {
    min-height: 20px;
    width: 40px;
    background-color: #6E76F2;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0 5px;
}

.legend-icon.pending {
    background-color: transparent;
    border-left: 4px solid #6E76F2;
    background-image: linear-gradient(135deg, #5555554c 5%, #6E76F240 5%, #6E76F240 20%, #5555554c 20%, #5555554c 25%, #fff 25%, #fff 50%, #5555554c 50%, #5555554c 55%, #6E76F240 55%, #6E76F240 70%, #5555554c 70%, #5555554c 75%, #fff 75%, #fff 100%);
    background-size: 6px 6px;
}

.legend-icon.completed {
    border-left: 4px solid #6E76F2;
    background-color:#6e77f240;
}

.legend-icon.availability {
    background-color: rgba(110,118,242,0.33);
}

.legend-icon.block {
    position: relative;
    background-color: #cdcdcd;
}

.legend-icon.block:before {
    --color: #6a6a6a;
    --color2: rgb(94 94 94);
    content: '';
    opacity: .1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(135deg, var(--color2) 5%, var(--color, #6E76F2) 5%, var(--color, #6E76F2) 20%, var(--color2) 20%, var(--color2) 25%, transparent 25%, transparent 50%, var(--color2) 50%, var(--color2) 55%, var(--color, #6E76F2) 55%, var(--color, #6E76F2) 70%, var(--color2) 70%, var(--color2) 75%, transparent 75%, transparent 100%);
    background-size: 6px 6px;
}
.legend-icon.window {
    background-color: #FFBE88;
    border-color: #FFBE88;
}

.legend-icon.instant {
    background-color: #6E76F2;
    border-color: #6E76F2;
}

