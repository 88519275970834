.stories-mockup-container {
    position: relative;
    font-family: "Jost",sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.stories-mockup-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;
    box-shadow: inset 0 -4px 0 0 currentColor;
    color: rgba(0,0,0,.64);
    -webkit-text-fill-color: rgba(0,0,0,.64);
}

.stories-mockup-container .shop-avatars-wrapper.no-logo {
    position: relative;
    width: 100%;
    padding: 4em 4em 1em 4em;
}

.stories-mockup-container .shop-avatars-wrapper.no-logo .shop-avatars-content {
    display: block;
    padding-top: 30%;
    position: relative;
    width: 100%;
    height: 0;
}

.stories-mockup-container .shop-avatars-wrapper.no-logo .shop-avatars-content .available-hosts {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sharp {
    border-radius: 8px;
}

.round {
    border-radius: 32px;
}

.intro-main-layout {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
}

.intro-main-layout .main-buttons-wrapper {
    margin-bottom: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

}

.intro-main-layout .main-buttons-wrapper .chat-link-container {
    margin-bottom: 2.4em;
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
    -webkit-text-fill-color: #fff;
    line-height: 1.5em;
    min-height: 1.5em;
    transition: .3s;
    cursor: pointer;
}

.intro-main-layout .main-buttons-wrapper .chat-link-container.contrast {
    color: currentColor;
    -webkit-text-fill-color: currentColor;
}

.intro-main-layout .main-buttons-wrapper .main-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1.4em;
}

.intro-main-layout .main-buttons-wrapper .main-buttons .schedule {
    border-style: solid;
    border-width: 1px;
    border-color: var(--main-color);
    background-color: white;
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);
    transition: .2s;
}

.intro-main-layout .main-buttons-wrapper .main-buttons.single .schedule {
    max-width: 184px;
    width: 100%;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    border: none;
    background-color: var(--main-color);
}

.preview-dialog {
    width: 100%;
    height: 100%;
    max-width: 480px;
    max-height: 700px;
    padding: 20px;
    border: none;
    margin: auto;
    background: var(--p-surface);
    box-shadow: var(--p-modal-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    opacity: 0;
    animation: previewDialog_animation .2s forwards;
}

.close-preview-container {
    position: absolute;
    top: 24px;
    right: 24px;
}

.preview-dialog-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--p-backdrop, rgba(33, 43, 54, 0.4));
    animation: Polaris-Backdrop__fade--in 200ms 1 forwards;
    z-index: 999;
}

.close-preview-container .close-preview-dialog-btn {
    color: #fff;
    -webkit-text-fill-color: #fff;
    background-color: rgba(0,0,0,.16);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    height: 32px;
    width: 32px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    position: relative;
    border: none;
}

@keyframes previewDialog_animation {
    0% {
        opacity: 0;
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 500px) {
    .preview-dialog {
        padding: 0;
     }
}

@media (max-width: 479px) {
    .preview-dialog {
       width: 100%;
       max-width: 100%;
       margin: auto 0 !important;
       padding: 0;
    }

    .stories-mockup-container {
        height: 100%;
        width: 100%;
    }

    .close-preview-container {
        position: absolute;
        top: 24px;
        right: 24px;
    }
}
