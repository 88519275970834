.email-footer {
    font-size: 11px;
    color: #BDBCBC;
    text-align: center;
    margin-top: 1rem;
}

.preview-container {
    border: 1px solid #BDBCBC;
}

.preview-accent {
    height: 5px;
    margin: 0px 5px 16px 5px;
}

.preview-logo {
    padding-top: 5px;
    width: 140px;
    object-fit: contain;
}

.personal-note {
    font-style: italic;
    padding-left: 1rem;
}

.custom-text-preview {
    white-space: pre-wrap
}
