.active-host-avatars-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;


}

.active-host-avatars-container .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -15px;
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
    background: white;
}


.active-host-avatars-container .avatar-container:after {
    border-radius: 50%;
    border: 4px solid white;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
}

.active-host-avatars-container .avatar-container .avatar {
    object-fit: cover;
    height: 58px;
    width: 58px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.active-host-avatars-container .avatar-container .avatar-initials {
    height: 58px;
    width: 58px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
