.metadata {
    border-radius: 2ch;
    background: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 0.2ch .7ch;
    animation: appearingAnimation .25s cubic-bezier(0.280, 1.240, 0.420, 1) forwards;
}

.metadata.views {
    left: auto;
    right: 10px;
}

.metadata-icon .Polaris-Icon {
    width: 1rem;
    height: 1rem;
    margin-top: 2px;
}

.metadata-icon.view .Polaris-Icon {
    width: 1rem;
    height: 1rem;
}

@keyframes appearingAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
