.card {
  width: 110px;
  height: 100%;
  position: relative;
  --background: #fff;
  --main-color: #6E76F2;
  --background-image: #fff, rgba(0, 107, 175, 0.2);
  --text-color: #666;
  --text-headline: #fff;
  --card-height: 100%;
  --card-width: 110px;
  --card-radius: 12px;
  --header-height: 67px;
  --blend-mode: overlay;
  --transition: 0.15s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card.disabled {
  pointer-events: none;
}

.card.checked .card__body::after {
  opacity: 1;
}

.card.checked .video-addBtn {
  cursor: default;
  pointer-events: none;
  animation: hiddingAnimation .2s cubic-bezier(0.99, 0.4, 0.420, 1) forwards;
}

.card__input:disabled~.card__body {
  cursor: not-allowed;
  opacity: 0.5;
}

.card__body {
  display: grid;
  grid-template-rows: 1fr auto;
  background: var(--background);
  height: var(--card-height);
  width: var(--card-width);
  border-radius: var(--card-radius);
  overflow: hidden;
  position: relative;
  border: 1px solid #c2c2c2;
}

.card__body::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--card-radius);
  transition: .14s opacity;
  will-change: opacity;
  opacity: 0;
  border: 3px solid var(--main-color);
}

.card__body-cover {
  --c-border: var(--card-radius) var(--card-radius) 0 0;
  --c-width: 100%;
  --c-height: 100%;
  border-top-left-radius: var(--card-radius);
  border-top-right-radius: var(--card-radius);
  width: 110px;
  height: 210px;
  position: relative;
  overflow: hidden;
  border-bottom: none;
  display: grid;
}

.product-config-button {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
}

.product-config-button.primary {
  background-color: var(--main-color);
}

.product-config-button.secondary {
  background-color: rgba(255, 255, 255, .4);
}

.product-config-button .Polaris-Icon {
  width: 1.4rem;
  height: 1.4rem;
  fill: #fff;
}

.card__body-cover-checkbox {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
  padding: 4px;
  background: rgb(211, 56, 60);
  border-radius: 50%;
}
.card__body-cover-checkbox .Polaris-Icon {
  width: 1.4rem;
  height: 1.4rem;
  fill: #fff;
}

.video-addBtn {
  top: 10px;
  left: 10px;
  border-radius: 2ch;
  overflow: hidden;
  justify-self: flex-start;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  padding: 4px;
  animation: appearingAnimation .25s cubic-bezier(0.280, 1.240, 0.420, 1);
}

.video-addBtn::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-color);
  opacity: 1;
  transition: .14s opacity;
}

.video-addBtn::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(44, 32, 32, 0.2);
  opacity: 0;
  transition: .14s opacity;
}

.card__body-header-play {
  align-self: center;
  justify-self: center;
  padding: 1.4rem;
  border-radius: 50%;
  border: 1px solid #c2c2c2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7);
  animation: appearingAnimation .25s cubic-bezier(0.280, 1.4, 0.420, 1) forwards;
}

.card__body-header-play .Polaris-Icon__Svg {
 transition: .14s fill;
}

.card__body-header-play:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: #fff;
  transition: .14s opacity;
}

.card__body-header-play:hover .Polaris-Icon__Svg {
  fill: var(--main-color);
}

.card__body-header-play:hover:before {
  opacity: 1;
}

.video-addBtn .Polaris-Icon {
  width: 1.4rem;
  height: 1.4rem;
  fill: #fff;
}

@keyframes posterAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearingAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hiddingAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.15);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
