
.react-datepicker-wrapper .react-datepicker__input-container .react-datepicker-ignore-onclickoutside {
    width: 100%;
}

.datepicker-container {
    display: flex;
    flex-flow: row nowrap;
}

.option-label {
    width: 70px;
}

.custom-datepicler-container {
    font-size: 14px;
}

.custom-popper-datepicler-container {
    z-index: 1000;
}

.custom-datepicler-container.react-datepicker {
    border-radius: 0.3em;
}

.custom-datepicler-container.react-datepicker .react-datepicker__navigation--previous {
    line-height: 1.7em;
    text-indent: -999em;
}

.custom-datepicler-container.react-datepicker .react-datepicker__navigation--next {
    line-height: 1.7em;
    text-indent: -999em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__header {
    border-top-left-radius: 0.3em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
    font-size: 0.944em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    width: 1.7em;
    line-height: 1.7em;
    margin: 0.166em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__month {
    margin: 0.4em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
    width: 1.7em;
    line-height: 1.7em;
    margin: 0.166em;
}

.custom-datepicler-container .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
    background: #6E76F2;
}

.custom-datepicler-container .react-datepicker__time-container {
    width: 100px;
}

.custom-datepicler-container .react-datepicker__time-container .react-datepicker__header.react-datepicker__header--time {
    border-top-right-radius: 0.3em;
}

.custom-datepicler-container .react-datepicker__time-container .react-datepicker__header.react-datepicker__header--time .react-datepicker-time__header {
    font-size: 0.944em;
}

.custom-datepicler-container .react-datepicker__time-container .react-datepicker__time {
    border-bottom-right-radius: 0.3em;
}

.custom-datepicler-container .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: #6E76F2;
}

.custom-datepicler-container .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-bottom-right-radius: 0.3em;
    width: 100%;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 110px;
}
