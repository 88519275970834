
:root{
    --font-color: var(--base-font-color);
    --font-size: 12px;
    --gap: .5px
}

.multiple-host-avatar-container {
    height: 77px;
    width: 77px;
    display: flex;
    flex-flow: row wrap;
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: space-between;
    align-content: space-between;
}

.multiple-host-avatar-container.row {
    align-content: center; 
}
.multiple-host-avatar-container .host-avatar-container-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - var(--gap));
    /* flex: 1 1 calc(50% - var(--gap)); */
    max-width: calc(50% - var(--gap));
    max-height: calc(50% - var(--gap));
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
    color: var(--font-color);
    -webkit-text-fill-color: var(--font-color);
}

.multiple-host-avatar-container .host-avatar-container-wrapper:after {
    border-radius: 50%;
    border: 1px solid white;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .2);
}

.multiple-host-avatar-container .host-avatar-container-wrapper .host-avatar {
    object-fit: cover;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size);
    font-weight: 500;
    background-color: white;
}

.multiple-host-avatar-container .host-avatar-container-wrapper .host-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    font-size: var(--font-size);
    font-weight: 500;
    background-color: white;
}
