.login-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-row{
  display: flex;
  flex-wrap: wrap;
}

.login-content{
  flex: 50%;
  text-align: center;
  padding: 2rem 4rem;
  height: 430px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.login-content .logo {
  height: 42px;
  align-self: center;
  margin-bottom: 1rem;
}

.login-content .trailing {
  align-self: flex-end;
}

.login-content .login-form{
  width: 100%;
  margin: 2rem 0;
}

.login-media{
  flex: 50%;
  background: #C8E4E0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-media img{
  width: 100%;
}

.login-cta{
  margin: 1rem 0;
}