.showroom-preset-products-wrapper {
    flex-flow: row;
    display: flex;
    margin-top: 12px;
    overflow-y: auto;
}

.empty-product-thumbnail {
    display: flex;
    width: 5rem;
    height: 5rem;
    border-radius: 0.25rem;
    border: var(--p-border-divider);
    align-items: center;
    justify-content: center;
}

.empty-product-title {
    text-align: center;
    font-size: 10px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.Polaris-Thumbnail {
    border: var(--p-border-divider) !important;
    margin: 0 4px;
}

.Polaris-Thumbnail--transparent {
    border: 0 !important;
}
