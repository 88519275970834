.Polaris-Card__Section.Polaris-Card__Section--flush {
    margin-top: 16px;
}

.story-product-search-container {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
}

.story-search-field-wrapper {
    overflow: hidden;
    min-width: calc(100% * .8);
    max-width: 100%;
    width: 100%;
}

.story-search-field-wrapper .story-search-field {
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: .2s transform ease-in-out;
    will-change: transform;
}

.story-search-field-wrapper.active .story-search-field {
    transform: translate3d(0, 0, 0);
}
