.video-status-effects-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-status-effects-container .publish-badge-container {
    position: absolute;
    top: 10px;
    left: 10px;
}

.video-status-effects-container .processing-container {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
}

.video-status-effects-container .error-container {
    padding: 5px;
    font-size: 10px;
}

.loader-container {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    animation: uploadingStatusAnimation .2s forwards;
}

.loader-container .loader-item {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
}

.status-message {
    color: #fff;
    font-weight: 500;
}

.status-message-subline {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    display: block;
    text-align: center;
}

@keyframes uploadingStatusAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
