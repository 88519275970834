.intro-main-layout-wrapper {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border-radius: var(--app-border-radius);
    font-family: var(--font-family);
}

.intro-main-layout-wrapper .intro-main-layout {
    grid-row: 3;
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper {
    margin-bottom: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1.4em;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons.single .schedule {
    max-width: 184px;
    width: 100%;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    border: none;
    background-color: var(--main-color);
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .schedule {
    border-style: solid;
    border-width: 1px;
    border-color: var(--main-color);
    background-color: white;
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);
    transition: .2s;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button {
    text-transform: uppercase;
    font-weight: 600;
    border-radius: var(--border-radius-m);
    height: 56px;
    outline: none;
    margin: 0;
    width: 184px;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family);
    cursor: pointer;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button.round {
    border-radius: 32px;
}

.intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button.filled-button {
    background-color: var(--main-color);
    border: none;
    color: #fff;
    -webkit-text-fill-color: #fff;
}

.chat-link-preview-container {
    margin-bottom: 2.4em;
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
    -webkit-text-fill-color: #fff;
    line-height: 1.5em;
    min-height: 1.5em;
    transition: .3s;
    cursor: pointer;
}

.chat-link-container.contrast {
    color: currentColor;
    -webkit-text-fill-color: currentColor;
}

.sound-control-container {
    border-radius: 50%;
    height: 2em;
    width: 2em;
    position: absolute;
    bottom: 24px;
    left: 24px;
    overflow: hidden;
    z-index: 1;
    animation: showArrows .3s;
    font-size: 16px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.sound-control-container .sound-control {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
}

.sound-control-container .sound-control::before {
    content: '';
    position: absolute;
    background-color: #00000028;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@keyframes showArrows {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 479px) {
    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr 1fr;
        gap: 1em;
        margin-bottom: 1em;
        padding: 0 1em;
    }


    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .connect {
        padding: 10px 14px;
        width: auto;
        height: auto;
    }

    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons .schedule {
        padding: 10px 14px;
        width: auto;
        height: auto;
    }

    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons.single {
        justify-items: center;
    }

    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons.single .schedule {
        max-width: 184px;
        width: 100%;
        color: #ffffff;
        -webkit-text-fill-color: #ffffff;
        border: none;
        background-color: var(--main-color);
    }

    .intro-main-layout-wrapper .intro-main-layout .main-buttons-wrapper .main-buttons.single .connect {
        max-width: 184px;
        width: 100%;
    }

    .chat-link-container {
        margin-bottom: 1.2em;
    }

    .chat-link-container .chat-link {
        display: flex;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        align-self: center;
        justify-content: center;
        font-weight: 600;
    }
}

