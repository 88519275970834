.timezone-selector {
  display: flex;
  position: relative;
  padding: 0px 0 8px 0;
  width: 100%;
  justify-content: center;

  .timezone-selector-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    .slots-timezone-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    }

    svg.down-caret {
      color: rgba(0, 0, 0, 0.4);
      transform: rotate(90deg);
      width: 16px;
      margin-left: 6px;

      &.rotate {
        transform: rotate(270deg);
      }
    }
  }


}

.locale-item {
  padding: 10px;
  line-height: 1;
  position: relative;
  width: 320px;
  text-align: start;
  flex-flow: row;
  display: flex;
  justify-content: space-between;

  .timezone-time {
    color: rgba(0, 0, 0, 0.4);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
  }

  &:after,
  &:before {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, .14);
    opacity: 0;
    transition: .14s opacity;
    will-change: opacity;
  }

  &:hover {
    &:before {
      opacity: .4;
    }
  }

  &.selected {
    &:after {
      opacity: 1;
    }
  }
}