.customer-profile-container {
    font-family: "Jost",sans-serif;
    display: grid;
    gap: 20px;
}

.customer-profile-container .customer-name-section {
    display: grid;
    gap: 12px;
    justify-content: center;
}

.customer-profile-container .customer-name-section .customer-name {
    font-size: 24px;
    font-weight: 600;
}

.customer-profile-container .customer-name-section .customer-email {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.64);
}

.customer-profile-container .purchase-data-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 16px;
}

.customer-profile-container .purchase-data-section .purchase-data-item {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    gap: 4px 8px;
    align-items: center;
    border: 1px solid #ccc3bd;
    border-radius: 16px;
    padding: 5px 10px;
    overflow: hidden;
    position: relative;
}

.customer-profile-container .purchase-data-section .purchase-data-item meter {
    position: absolute;
    bottom: -5px;
    width: 100%;
}

meter::-webkit-meter-bar {
    background-color: #ccc3bd;
    border: none;
  }
meter::-webkit-meter-optimum-value {
    background-image: linear-gradient(#6E76F2 50%, #6E76F2 50%);
    background-size: 100% 100%;
}

@media not all and (min-resolution:.001dpcm) { 
    meter {
      -webkit-appearance: none;
      appearance: none;
  }
}

.customer-profile-container .purchase-data-section .purchase-data-item .icon-container {
    grid-column: 1;
    grid-row: 1 / 3;
    height: 27px;
    width: 27px;
}

.customer-profile-container .purchase-data-section .purchase-data-item .value {
    font-size: 18px;
    font-weight: 500;
}

.customer-profile-container .customer-additional-notes-section {
    display: grid;
    gap: 2px 12px;
}

.customer-profile-container .customer-additional-notes-section .customer-additional-notes-item {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1ch;
}

.customer-profile-container .customer-additional-notes-section .customer-additional-notes-item .icon-container {
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customer-profile-container .customer-additional-notes-section .customer-additional-notes-item .label-container {
    font-size: 16px;
    display: flex;
    flex-flow: row nowrap;
}

.customer-profile-container .customer-additional-notes-section .customer-additional-notes-item .label-container .label {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20ch;
}

.customer-profile-container .customer-additional-notes-section .customer-additional-notes-item .value {
    font-size: 16px;
}

.customer-profile-container .products-row-section {
    margin-top: 20px;
    overflow: hidden;
}

.customer-profile-container .products-row-section .products-row-label-container{
    display: grid;
    gap: 12px;
    grid-auto-flow: column;
    justify-content: flex-start;
    font-size: 18px;
}

.customer-profile-container .products-row-section .products-row-label-container .label{
    font-size: 18px;
}

.customer-profile-container .products-row-section .products-row-label-container .label.multiple{
    cursor: pointer;
    opacity: .6;
    transition: .15s;
    will-change: opacity;
}

.customer-profile-container .products-row-section .products-row-label-container .label.multiple.active{
    opacity: 1;
}

.customer-profile-container .products-row-section .products-array-container-wrapper {
    width: 100%;
    overflow-x: auto;
    padding-top: 12px;
}

.customer-profile-container .products-row-section .products-array-container-wrapper .products-array-container{
    display: grid;
    grid-auto-flow: column;
    gap: 12px;
    padding-bottom: 6px;
}

.customer-profile-container .products-row-section .products-array-container-wrapper .products-array-container .product-item{
    height: 145px;
    width: 96px;
    background-color: rgb(245, 245, 245);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d1d1;
}

.customer-profile-container .products-row-section .products-array-container-wrapper .products-array-container .product-item .product-image{
    display: flex;
    width: 50%;
}
