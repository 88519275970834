.boutiq-shoppable-widget {
    all: inherit;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    bottom: 25px;
    background-color: transparent;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    outline: none;
    border: none;
    box-shadow: 5px 5px 10px grey;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    z-index: 101;
    transition: all 0.2s ease-out;
    cursor: pointer;
    line-height: 1.5;
    padding: 0;
}

.boutiq-shoppable-widget.standard.circle {
    width: 100%;
    min-width: 160px;
    max-width: 160px;
    height: 160px;
    min-height: 160px;
    border-radius: 50%;
}

.boutiq-shoppable-widget.standard.rectangle {
    width: 100%;
    min-width: 160px;
    max-width: 160px;
    height: 200px;
    min-height: 200px;
    border-radius: 8px;
}

.standard.bottom_left {
    right: calc(100vw - 200px);
    left: 25px;
}

.standard.bottom_right {
    left: calc(100vw - 200px);
    right: 25px;
}

.boutiq-shoppable-widget.large.circle {
    width: 100%;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-height: 240px;
    border-radius: 50%;
}

.boutiq-shoppable-widget.large.rectangle {
    width: 100%;
    min-width: 240px;
    max-width: 240px;
    height: 300px;
    min-height: 300px;
    border-radius: 8px;
}

.large.bottom_left {
    right: calc(100vw - 280px);
    left: 25px;
}

.large.bottom_right {
    left: calc(100vw - 280px);
    right: 25px;
}

.video-element{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s ease-in-out;
    will-change: opacity;
}

.video-element.hidden {
    opacity: 0;
}

.video-poster.visible {
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: .2s ease-in-out;
}

.video-poster.hidden {
    opacity: 0;
}

.video-container-wrapper {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    flex: 1 0 60px;
    box-shadow: 5px 5px 10px grey;
}

.video-container {
    overflow: hidden;
    border-radius: 8px;
    height: 300px;
    width: 240px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);
}

.video-poster {
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: .2s ease-in-out;
    will-change: transform, opacity;
    transform-origin: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    position: absolute;
    object-fit: cover;
}
