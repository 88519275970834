.selected-tag {
    color: #A9A9A9;
    -webkit-text-fill-color: #A9A9A9;
    font-size: 12px;
    border: 1px solid #B2B2B2;
    border-radius: 2px;
    width: 104px;
    background-color: white;
    z-index: 1;
    text-align: center;
}

.selected-tag.shadow {
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.13);
}
