.blacklist__pagination {
    margin-top: 20px;
    right: 0;
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.blacklist__resource-item {
    flex: 1;
    flex-direction: row;
    display: flex;
    width: 85%;
}

.blacklist-item__content {
    flex: 1;
    flex-direction: row;
    display: flex;
    width: 85%;
}

.blacklist-item__status {
    margin-left: 20px;
}

.blacklist-item__description {
    margin-top: 20px;
}
