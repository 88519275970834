.image-error-message-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-error-message-container p {
    font-size: 12px;
}

.card__body-cover-image {
    width: var(--c-width);
    height: var(--c-height);
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: var(--c-border);
    filter: var(--filter-bg, grayscale(0));
    opacity: 0;
    transition: 1.4s filter;
    animation: posterAnimation .14s forwards;
    animation-play-state: paused;
}

.card__body-cover-image.loaded {
    animation-play-state: running;
}

.card__body-cover-image.unactive {
    filter: var(--filter-bg, grayscale(1)) blur(1px);
}
