.intro-welcome-mockup-container {
    position: relative;
    font-family: var(--font-family);
    height: 600px;
    width: 440px;
    overflow: hidden;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.intro-welcome-mockup-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;
    box-shadow: inset 0 -4px 0 0 currentColor;
    color: rgba(0,0,0,.64);
    -webkit-text-fill-color: rgba(0,0,0,.64);
}

.intro-welcome-mockup-container .shop-avatars-wrapper.no-logo {
    position: relative;
    width: 100%;
    padding: 4em 4em 1em 4em;
}

.intro-welcome-mockup-container .shop-avatars-wrapper.no-logo .shop-avatars-content {
    display: block;
    padding-top: 30%;
    position: relative;
    width: 100%;
    height: 0;
}

.intro-welcome-mockup-container .shop-avatars-wrapper.no-logo .shop-avatars-content .available-hosts {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sharp {
    border-radius: 8px;
}

.round {
    border-radius: 32px;
}
