.feedback-container {
    overflow: hidden;
    max-width: 80px;
    z-index: 100;
}

.feedback-container .feedback-score-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-container .feedback-message-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    will-change: opacity;
    transition: .2s opacity;
    text-decoration: underline;
    text-align: center;
}
.feedback-container .feedback-message-container:hover {
    opacity: .6;
}

tbody .Polaris-DataTable__Cell{
    cursor: pointer;
}

.host-search-wrapper .Polaris-Connected__Item:not(:first-child) {
    left: 350px;
    transition: left .4s;
}

.host-search-wrapper.hide .Polaris-Connected__Item:not(:first-child) {
    margin-left: 0;
    left: 0;
}

.host-search-wrapper .Polaris-Connected__Item--primary {
    position: absolute;
    width: 350px;
    flex: 1 1 auto;
    opacity: 1;
    transition: width .5s, opacity .1s, flex .1s;
}

.host-search-wrapper .Polaris-TextField {
    width: 100%;
    opacity: 1;
    transition: width .5s, opacity .1s;
}

.host-search-wrapper.hide .Polaris-TextField .Polaris-TextField__Input {
    opacity: 1;
    transition: opacity .1s;
}


.host-search-wrapper.hide .Polaris-Connected__Item--primary {
    flex: none;
    width: 0;
    opacity: 0;
}

.host-search-wrapper.hide .Polaris-TextField {
    width: 0;
    opacity: 0;
}

.host-search-wrapper.hide .Polaris-TextField .Polaris-TextField__Input {
    padding: 0;
    opacity: 0;
}

.call-history-filters-wrapper {
    width: auto;
    opacity: 1;
    transition: width .5s, opacity 0ms;
    white-space: nowrap;
}

.call-history-filter-container .Polaris-Stack {
    flex-wrap: unset;
}

.call-history-filters-wrapper .Polaris-Stack--spacingLoose .Polaris-Stack--distributionTrailing{
    width: auto;
    opacity: 1;
    transition: width .5s, opacity .5s;
}

.call-history-filters-wrapper.hide {
    width: 0;
    opacity: 0;
}

.call-history-filters-wrapper.hide .Polaris-Stack--spacingLoose .Polaris-Stack--distributionTrailing {
    width: 0;
    opacity: 0;
}

