.host-data-container {
    display: grid;
    grid-auto-flow: column;
    gap: 2px 15px;
    cursor: pointer;
    padding: .4em 1em;
    position: relative;
    width: 100%;
    transition: .14s background, .14s color;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
}


.host-data-container.checked .host-name {
    color: white;
    -webkit-text-fill-color: white;
}

.host-data-container .host-avatar-container {
    z-index: 1;
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
    width: 77px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
}

.host-data-container .host-avatar-container:after {
    border-radius: 50%;
    border: 4px solid white;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
}

.host-data-container .host-avatar-container .host-avatar {
    object-fit: cover;
    height: 68px;
    width: 68px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    background-color: white;
}

.host-data-container .host-avatar-container .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    width: 68px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    background-color: white;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
}

.host-data-container .host-name {
    z-index: 1;
    grid-row: 1;
    grid-column: 2;
    font-weight: 500;
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.host-data-container .host-bio {
    z-index: 1;
    grid-row: 2;
    grid-column: 2;
    opacity: .6;
    font-size: 14px;
    line-height: 1.2;
}

.host-data-container .empty-host-bio-container {
    font-size: 14px;
    line-height: 1.2;
}

.host-data-container .empty-host-bio-container p {
    margin-top: 2px;
}

.host-data-container .host-bio.alert {
    color: var(--p-icon-warning);
    -webkit-text-fill-color: var(--p-icon-warning);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    align-items: center;
    align-self: flex-start;
}

.host-data-container .host-bio.alert .alert-message {
    margin-left: 1ch;
}

.host-data-container:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--main-color);
    opacity: 0;
    transition: .14s opacity;
    will-change: opacity;
}

.host-data-container:hover:before {
    opacity: .1;
}

.host-data-container.scheduled.selected:before {
    opacity: .4;
}

.host-data-container.scheduled:before {
    opacity: .4;
}