.field-container {
    &:not(.edit-mode) {
        cursor: pointer;
    }
}

.edit-tools-container {
    display: grid;
    gap: 16px;
    align-items: flex-start;

    &:not(.edit-mode) {
        cursor: pointer;
    }

    .order-tools-container {
        grid-row: 1;
        grid-column: 3;
        display: grid;
        grid-auto-flow: row;
        align-items: flex-start;
        grid-template-rows: max-content;
    }

    .common-edit-tools-container {
        grid-row: 1;
        grid-column: 2;
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
        justify-content: flex-start;
        align-items: center;
    }
}


.form-control-section {
    border-radius: var(--p-border-radius-wide, 3px);
    background-color: var(--p-surface);
    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    outline: 0.1rem solid transparent;
}
