/*
.onboard-container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboard-container .onboard-content{
  margin: 4rem;
  text-align: center;
}

.onboard-container .onboard-cta{
  margin: 4rem 0 1rem ;
}
*/

.onboard-header {
  background-color: #ff9500;
  padding: 4px;
  color: white;
}

.onboard-header .caazam-stars{
height: 50px;
transform: translate(0,20%);

}

.onboard-plan {
  display: flex;
  justify-content: center;
  align-items: center;    
}