.event-type-limit-width {
    max-width: 320px;
}

.event-type-color-picker-wrapper { 
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-type-color-picker-wrapper .color { 
    width: 276px;
    border-top-width: 5px;
    border-top-style: solid;
    padding-bottom: 10px;
}