.progress-video:not(:last-child) {
    padding-right: 4px;
}

.progress-video {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    height: 2px;
    display: flex;
}

.progress-video .progress {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    height: 2px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.14);
}

.progress-video .progress .progress-bar {
    width: 100%;
    height: 2px;
    display: flex;
    background-color: var(--main-color);
    transform: translateX(calc(-100% - 4px));
    left: 0;
    right: 0;
    animation-timing-function: linear;
}

.progress-video .progress .progress-bar.exist {
    animation-fill-mode: both;
}

.progress-video .progress .progress-bar.active {
    animation-name: progressAnimation;
}

.progress-video .progress .progress-bar.playing {
    animation-play-state: running;
}

.progress-video .progress .progress-bar.paused {
    animation-play-state: paused;
}

.progress-video .progress .progress-bar.done {
    transform: translateX(0);
}

@keyframes progressAnimation {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}
