.custom-form-input-container {
    position: relative;
    display: flex;
    padding: 0.25rem 1.2rem;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        border: 1px solid var(--p-border-subdued);
        border-top-color: var(--p-border-shadow);
        border-radius: var(--p-border-radius-base);
        will-change: opacity;
        transition: .14s opacity;
        pointer-events: none;
    }

    &:before {
        content: '';
        bottom: 0;
        position: absolute;
        border-bottom: 1px solid var(--p-divider);
        opacity: 0;
        will-change: opacity;
        width: 100%;
        transition: .14s opacity;
    }

    &:not(.active) {
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }

    &.active {
        &:after {
            opacity: 1;
        }
    }

    .custom-form-input {
        position: relative;
        border: none;
        font-size: 16px;
        color: #3f3f3f;
        -webkit-text-fill-color: #3f3f3f;
        outline: none;
        width: 100%;
        line-height: 1.6em;


        &:before {
            content: attr(error-message-attr);
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            will-change: opacity;
            transition: .14s opacity;
            color: var(--p-text-subdued);
            font-size: inherit;
        }

        &.error {
            &:before {
                opacity: .4;
            }
        }
        &.main {
            font-size: 18px;
        }

        &:not(.option) {
            font-weight: 600;
        }

        &.option {
            font-size: 14px;
        }
    }
}
