.boutiq-product-card {
    max-width: 140px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.boutiq-product-card img {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
    object-position: center;
}