.shop-locales-container {
    transition: .15s opacity;
    will-change: opacity;
}

.shop-locales-container.disabled {
    opacity: .4;
    pointer-events: none;
}

.choice-item {
    margin: -.4rem 1rem 0 0;
}

.edit-button-wrapper {
    padding-bottom: 14px;
    font-size: 12px;
    text-decoration: underline;
}
