.video-player-container {
    height: 70vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}


.video-player-container .video-player {
    max-width: 356px;
    max-height: 70vh;
    height: 100%;
}