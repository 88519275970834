.collapsible-container {
    z-index: 1;
}

.event-bio {
    z-index: 1;
    color: #66676C;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height .4s cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow: hidden;
    max-height: var(--default-max-height);
    animation: close 0s .4s forwards;
    line-clamp: var(--countOfRows);
    -webkit-line-clamp: var(--countOfRows);
}

.event-bio.open {
    animation: open 0s forwards;
}

@keyframes open {
    from {
        line-clamp: var(--countOfRows);
        -webkit-line-clamp: var(--countOfRows);
    }

    to {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
}

@keyframes close {
    from {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }

    to {
        line-clamp: var(--countOfRows);
        -webkit-line-clamp: var(--countOfRows);
    }
}
.event-description-toggle-button {
    justify-self: flex-start;
    color: #66676C;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 6px 6px 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: none;
}

.event-description-toggle-button.available {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    display: flex;
}

.event-description-toggle-button .event-description-toggle-button-animated-labels-container {
    overflow: hidden;
    position: relative;
    height: 1.2em;
}

.event-description-toggle-button .event-description-toggle-button-animated-labels-container .event-description-toggle-button-label {
    line-height: 1.2em;
    transform: translateY(0);
    opacity: 1;
    will-change: opacity, transform;
    white-space: nowrap;
    text-align: left;
    transition: .1s opacity, .1s transform ease-in-out;
}

.event-description-toggle-button .event-description-toggle-button-animated-labels-container.open .event-description-toggle-button-label {
    transform: translateY(-100%);
    opacity: 0;
    transition: .1s opacity, .1s transform ease-in-out;
}

.event-description-toggle-button .event-description-toggle-button-animated-labels-container.open .event-description-toggle-button-label.active {
    transform: translateY(-100%);
    opacity: 1;
    transition: .3s opacity, .3s transform ease-in-out;
}
