.error-message {
    color: red;
    font-size: 14px;
    display: flex;
    align-items: center;
 }

 .popover-message-container-wrapper {
     overflow: hidden;
     max-height: 200px;
 }

 .popover-message-container-wrapper .popover-message-container .message {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: .7em;
 }
 .popover-message-container-wrapper .popover-message-container .message.error {
    color: red;
 }
 .popover-message-container-wrapper .popover-message-container .message.warning {
    color: orange;
 }
