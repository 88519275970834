
.host-avatar-container {
    z-index: 1;
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .15);
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
}

.host-avatar-container:after {
    border-radius: 50%;
    border: 2px solid white;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
}

.host-avatar-container .host-avatar {
    object-fit: cover;
    height: 23px;
    width: 23px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    background-color: white;
}

.host-avatar-container .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    font-size: 10px;
    font-weight: 500;
    background-color: white;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
}
