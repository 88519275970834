.intro-content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 50%;
    margin-top: 30%;
}

.intro-content-wrapper .video-controls-container {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    margin: 0 24px;
}

.intro-content-wrapper .video-controls-container.no-logo {
    padding-top: 2em;
}

.intro-content-wrapper .video-controls-container .prev-control-container {
    order: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-content-wrapper .video-controls-container .prev-control-container .prev-story {
    padding: 6px;
    display: flex;
    margin-top: -2em;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    background-color: #00000028;
    touch-action: manipulation;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.intro-content-wrapper .video-controls-container .prev-control-container .prev-story:disabled {
    opacity: .2;
    cursor: pointer;
    z-index: 0;
}

.next-control-container {
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-control-container .next-story {
    padding: 6px;
    display: flex;
    margin-top: -2em;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    background-color: #00000028;
    touch-action: manipulation;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.next-control-container .next-story:disabled {
    opacity: .2;
    cursor: pointer;
    z-index: 0;
}

.state-observers {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    -webkit-text-fill-color: #fff;
    flex: 1;
    flex-flow: column;
    height: 100%;
    margin-top: -1em;
}

.welcome-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    animation: showWelcomeMessage 2s;
}

@keyframes showWelcomeMessage {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
