

.landing-header {
     display: flex;
     flex-wrap: wrap;
     gap: 8px;

     .landing-header-text {
          flex: 1 1 300px;
          min-width: 300px;
     }

     .landing-header-image {
          width: 360px;
          border: 1px solid #6E76F2;
         }
}