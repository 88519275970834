.video-loader-container {
    color: white;
    -webkit-text-fill-color: white;
}

.video-loader-container .video-loader {
    width: 80px;
    height: 80px;
    opacity: 0;
    transition: .2s opacity;
    will-change: opacity;
}

.video-loader-container .video-loader.visible {
    opacity: 1;
}

.video-loader-container .video-loader svg {
    width: 100%;
    height: 100%;
    transition: color .2s;
}

.video-loader-container .video-loader.invert svg {
    stroke: black;
}
