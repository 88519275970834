.intro-main-layout-wrapper-btns {
    width: 100%;
    display: flex;
    flex-flow: column;
    border-radius: var(--app-border-radius);
    box-shadow: inset 0 -4px 0 0 var(--main-color);
    font-family: var(--font-family);
}

.intro-main-layout-wrapper-btns .intro-main-layout {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    flex: 1 0 auto;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper {
    margin-bottom: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .main-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1.4em;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button {
    text-transform: uppercase;
    font-weight: 600;
    border-radius: var(--border-radius-m);
    height: 56px;
    outline: none;
    margin: 0;
    width: 184px;
    font-size: 16px;
    font-weight: 600;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button.round {
    border-radius: 32px;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .main-buttons .caazam-button.filled-button {
    background-color: currentColor;
    border: none;
    color: #fff;
    -webkit-text-fill-color: #fff;
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .main-buttons .schedule {
    border-style: solid;
    border-width: 1px;
    border-color: currentColor;
    background-color: white;
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);
}

.intro-main-layout-wrapper-btns .intro-main-layout .main-buttons-wrapper .chat-link-container {
    margin-bottom: 2.4em;
    font-weight: bold;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
    line-height: 1.5em;
    min-height: 1.5em;
    transition: .3s;
    cursor: pointer;
}
