.schedule-data-container-wrapper {
    display: grid;
    height: 100%;
    position: relative;
}

.schedule-data-container-wrapper .scheduling-loader-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spinnerFadeIn .25s forwards;
    will-change: opacity;
}
.schedule-data-container-wrapper .schedule-data-container {
    transform: .4s opacity;
    will-change: opacity;
}

.schedule-data-container-wrapper .schedule-data-container.pending {
    opacity: .4;
}

@keyframes spinnerFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
