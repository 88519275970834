.landing-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-row{
    display: flex;
    flex-wrap: wrap;
  }

  .landing-content{
    flex: 50%;
    text-align: center;
    padding: 2rem 4rem;
    height: 430px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .landing-media{
    flex: 50%;
    background: #C8E4E0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-media img{
    width: 100%;
  }

  .landing-cta{
    margin: 1rem 0;
  }