.custom-form-label-container {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;

    .custom-form-input-wrapper {
        display: grid;
        grid-auto-flow: column;
        align-items: baseline;

        button {
            margin: 0;
            margin-left: -30px;
        }
    }

    .onsubmit-buttons-container {
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
        justify-content: flex-start;
    }
}
