.widget-popup-container {
    max-height: 600px;
    height: fit-content;
    width: 380px;
    color: rgba(0,0,0,.64);
    background-color: #fff;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 24px 40px 0 rgb(0 0 0 / 16%);
}

.widget-popup-container .widget-popup-header {
    width: 100%;
    height: 50px;
    min-height: 50px;
    align-items: center;
    display: flex;
}

.widget-popup-container .widget-popup-header .widget-popup-header-text {
    color: #FFFFFF;
    -webkit-text-fill-color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.widget-popup-container .form-container {
    width: 80%;
    margin: 20px 10%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.widget-popup-container .form-container .form {
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

.widget-popup-container .form-container .form p {
    color: black;
    margin-bottom: 1rem;
}

.widget-popup-container .form-container .form .actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-popup-container .form-container .form .actions-container .caazam-button {
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 700;
    height: 40px;
    outline: none;
    min-width: 120px;
    width: auto;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    background-color: black;
    border: none;
    color: #fff;
    -webkit-text-fill-color: #fff;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 320px;
}

.widget-popup-image {
    object-fit: contain;
    width: 100%;
    max-height: 200px;
    margin-bottom: 20px;
}

.campains-modal-description {
    font-size: 15px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.5;
    margin: 0 !important;
    flex: 1 1 auto;
    overflow: hidden;
}

.campains-modal-description > * {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.5;
    margin: 0 !important;
}
