.online-shop-config-image {
    width: 80%;
    object-fit: contain;
}

.widget-preview-text {
  font-size: 0.8em;
}

.caazam-widget {
  all: inherit;
  margin: 0;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  bottom: 25px;
  right: 25px;
  background-color: #08b273;
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
  border-radius: 60px;
  outline: none;
  border: none;
  box-shadow: 5px 5px 10px grey;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  z-index: 513;
  transition: all 0.2s ease-out;
  cursor: pointer;
  border: 0px #ffffff solid;  /* for overridng shopify css  - just in case */
  line-height: 1.5;
}

.caazam-widget-container {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  animation: fadeInAvatar .2s ease-in-out forwards;
  opacity: 0;
  transform: scale(.4);
}

.caazam-widget.standard .caazam-widget-container {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 30px;
}

.caazam-widget.large .caazam-widget-container {
  height: 120px;
  width: 120px;
  min-width: 120px;
  border-radius: 60px;
}
.caazam-widget-container svg {
  fill: white !important;
}

.caazam-widget.standard {
  width: 100%;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 30px;
}

.caazam-widget.large {
  width: 100%;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 60px;
}

.caazam-widget .caazam-widget-host-avatar-container {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.caazam-widget .caazam-widget-host-avatar-container::before {
  background: rgba(255, 255, 255, 0.2);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.caazam-widget .caazam-widget-host-avatar-container.loaded::after {
  opacity: 0;
}

.caazam-widget .caazam-widget-host-avatar-container::after {
  content: '';
  position: absolute;
  background-image: url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 40 40">\<style> circle { animation: 4s a infinite linear, 3s o infinite linear; }\@keyframes a {from{stroke-dasharray:120 0}50%25{stroke-dasharray:0 120}to{stroke-dasharray:120 0}}\@keyframes o {from{stroke-dashoffset:40}to{stroke-dashoffset:400}}\<%2Fstyle><circle r="19.5" stroke-width="1.4" fill="none" stroke="snow" />\</svg>');
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: opacity;
  opacity: 1;
  z-index: 1;
  transition: .2s opacity .4s;
}

.caazam-widget.large .caazam-widget-host-avatar-container {
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
}

.caazam-widget.standard .caazam-widget-host-avatar-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
}

.caazam-widget .caazam-widget-host-avatar-container.loaded img {
  animation: fadeInAvatar .2s ease-in-out forwards;
}

@keyframes fadeInAvatar {
  0% {
    transform: scale(.4);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.caazam-widget .caazam-widget-host-avatar-container img {
  overflow: hidden;
  border-radius: 50%;
  opacity: 0;
  transform: scale(.4);
}

.caazam-widget.large .caazam-widget-host-avatar-container img {
  object-fit: cover;
  height: 120px;
  width: 120px;
}

.caazam-widget.standard .caazam-widget-host-avatar-container img {
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.caazam-widget.with-helper-text {
  transition: all 0.4s;
  white-space: unset;
}

.caazam-widget.large.with-helper-text {
  max-width: 220px;
}

.caazam-widget.standard.with-helper-text {
  max-width: 160px;
}

.caazam-widget.large .caazam-widget-helper-text-wrapper #caazam-widget-helper-text {
  -webkit-line-clamp: 4; /* number of lines to show */
}

#caazam-widget-helper-text {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  opacity: 0;
  animation: fadeIn 1s forwards;
  text-align: center;
  width: 100px;
  padding: 0 12px 0 0;
}

.caazam-widget .caazam-widget-helper-text-wrapper {
  overflow: hidden;
  width: 100px;
}

.with-helper-text {
  width: 160px;
  transition: all 0.4s;
  justify-content: space-between;
  white-space: unset;
  /* text-wrap: none; */
}

.caazam-widget.hidden {
  visibility: hidden;
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

.widget-config {
  margin: 0 20px;
}

.change-widget-position-backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 512;
  background-color: rgb(0 0 0 / 60%);
  display: grid;
  grid-template-columns: 161px auto 161px;
  grid-template-rows: 237px auto 237px;
}

.change-widget-position-backdrop .message {
  grid-row: 2;
  grid-column: 2;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-flow: column;
}

.change-widget-position-backdrop .message p{
  line-height: 60px;
  text-align: center;
}

.change-widget-position-backdrop .message .actions{
  display: flex;
  flex-flow: row wrap;
}

.change-widget-position-backdrop .message .actions button{
  margin: 10px 20px 0 0 ;
}

.change-widget-position-backdrop .access-area.top {
  display: grid;
  grid-template-columns: 85px 76px;
  grid-template-rows: 85px 152px;
}

.change-widget-position-backdrop .access-area.bottom {
  display: grid;
  grid-template-columns: 76px 85px;
  grid-template-rows: 152px 85px;
}

.change-widget-position-backdrop .access-area.top-left {
  grid-row: 1;
  grid-column: 1;
}
.change-widget-position-backdrop .access-area.top-right {
  grid-row: 1;
  grid-column: 3;
}
.change-widget-position-backdrop .access-area.bottom-left {
  grid-row: 3;
  grid-column: 1;
}
.change-widget-position-backdrop .access-area.bottom-right {
  grid-row: 3;
  grid-column: 3;
}

.change-widget-position-backdrop .access-area.top-left .side-left-area {
  grid-row: 1 / 3;
  grid-column: 1;
}

.change-widget-position-backdrop .access-area.top-left .side-top-area {
  grid-row: 1;
  grid-column: 2;
}

.change-widget-position-backdrop .access-area.top-right .side-right-area {
  grid-row: 1 / 3;
  grid-column: 2;
}

.change-widget-position-backdrop .access-area.top-right .side-top-area {
  grid-row: 1;
  grid-column: 1;
}

.change-widget-position-backdrop .access-area.bottom-left .side-left-area {
  grid-row: 1 / 3;
  grid-column: 1;
}

.change-widget-position-backdrop .access-area.bottom-left .side-bottom-area {
  grid-row: 2;
  grid-column: 2;
}

.change-widget-position-backdrop .access-area.bottom-right .side-right-area {
  grid-row: 1 / 3;
  grid-column: 2;
}

.change-widget-position-backdrop .access-area.bottom-right .side-bottom-area {
  grid-row: 2;
  grid-column: 1;
}

.change-widget-position-backdrop .access-area .target {
  position: relative;
}

.change-widget-position-backdrop .access-area .target::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: '';
  background: rgb(255 255 255 / 60%);
  opacity: 0;
  animation: active 4s infinite;
  animation-play-state: running;
  transition: .4s background, opacity;
}

.change-widget-position-backdrop .access-area.active .target::before {
  background: rgba(0, 208, 132, 0.75);
  opacity: 1 !important;
}

.change-widget-position-backdrop.allow .access-area .target::before {
  animation: none;
  opacity: 1! important;
}

.position-vizualizator-container {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  font-size: 12px;
}

.position-vizualizator-container.right {
  left: 100%;
  top: 50%;
  transform: translate3d(10%, -50%, 0);
}

.position-vizualizator-container.left {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.position-vizualizator-container div {
  display: flex;
  flex-flow: row nowrap;
}

.position-vizualizator-container div :first-child {
  margin-right: 1ch;
}

@keyframes active {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;

  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 767px) {

  .change-widget-position-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 512;
    background-color: rgb(0 0 0 / 60%);
    display: grid;
    grid-template-columns: 85px auto 85px;
    grid-template-rows: 85px auto 85px;
  }

  .change-widget-position-backdrop .message {
    font-size: 20px;
  }
  
  .change-widget-position-backdrop .message p{
    line-height: 30px;
    text-align: center;
  }
  
  .change-widget-position-backdrop .access-area.top {
    display: grid;
    grid-template-columns: 85px;
    grid-template-rows: 85px;
  }
  
  .change-widget-position-backdrop .access-area.bottom {
    display: grid;
    grid-template-columns: 85px;
    grid-template-rows: 85px;
  }


  .change-widget-position-backdrop .access-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .change-widget-position-backdrop .access-area.top-left .side-left-area {
    grid-row: 1;
    grid-column: 1;
  }
  
  .change-widget-position-backdrop .access-area.top-left .side-top-area {
    display: none;
  }
  
  .change-widget-position-backdrop .access-area.top-right .side-right-area {
    display: none;
  }
  
  .change-widget-position-backdrop .access-area.top-right .side-top-area {
    grid-row: 1;
    grid-column: 1;
  }
  
  .change-widget-position-backdrop .access-area.bottom-left .side-left-area {
    grid-row: 1;
    grid-column: 1;
  }
  
  .change-widget-position-backdrop .access-area.bottom-left .side-bottom-area {
    display: none;
  }
  
  .change-widget-position-backdrop .access-area.bottom-right .side-right-area {
    grid-row: 1;
    grid-column: 1;
  }
  
  .change-widget-position-backdrop .access-area.bottom-right .side-bottom-area {
    display: none;
  }  
}
