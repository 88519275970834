@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Polaris-TopBar-UserMenu__Details .Polaris-Text--subdued {
  color: #fff;
}

:root {
  --p-text-primary: #6E76F2 !important;
  --p-action-primary: #6E76F2 !important;
  --p-text-primary-hovered: #6E76F2 !important;
  --p-action-secondary-depressed: #5958ff !important;
  --p-action-primary-hovered:  rgba(58, 75, 235, 1) !important;
  --p-action-primary-pressed: rgba(37, 58, 219, 1) !important;
  --p-border-highlight: #6E76F2 !important;
}

.Polaris-TopBar {
  --p-surface: #6E76F2; 
}

.Polaris-TopBar-Menu__Activator {
  --p-text: white;
}

.Polaris-TopBar-Menu__Activator:hover {
  --p-surface-hovered: #BDC4FF;
}
.Polaris-Avatar {
  --p-decorative-one-surface: #C8E4E0;
  --p-decorative-two-surface: #BDC4FF;
  --p-decorative-three-surface: #6E76F2;
  --p-decorative-four-surface: #4D53AD;
  --p-decorative-five-surface: #FFBE88;
}

.Polaris-Avatar__Initials {
  color: white;
}

.Polaris-TopBar__NavigationIcon {
  --p-icon: white;
}

[data-polaris-tooltip-activator] {
  fill: #6E76F2; 
}

.Polaris-Frame-Loading__Level {
  --p-icon-success: #BDC4FF;
}

.link-pop-up-modal-terms #openLinkInNewWindow {
  pointer-events: none;
}

.link-pop-up-modal-terms .rdw-link-modal-target-option {
  opacity: .4;
  pointer-events: none;
}
