.shop-logo-container-wrapper {
    position: relative;
    width: 100%;
    padding: 2em 4em 1em 4em;
}

.shop-logo-container-wrapper .shop-logo-content-wrapper {
    display: block;
    padding-top: 30%;
    position: relative;
    width: 100%;
    height: 0;
}

.shop-logo-container-wrapper .shop-logo-content-wrapper .shop-logo-content {
    border-radius: 4px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.shop-logo-container-wrapper .shop-logo-content-wrapper .shop-logo-content .shop-logo {
    object-fit: contain;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    max-width: 350px;
    transform: translateX(-50%);
    opacity: 1;
}
