.play-interaction-container {
    color: white;
    -webkit-text-fill-color: white;
}

.play-interaction-container .play-interaction-button {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
}
