.select-host-mockup-container {
    position: relative;
    font-family: var(--font-family);
    height: 600px;
    width: 440px;
    overflow: hidden;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    transition: .14s opacity;
    will-change: opacity;
}
.select-host-mockup-container.disabled {
    pointer-events: none;
    opacity: .4;
}

.select-host-mockup-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;
    box-shadow: inset 0 -4px 0 0 currentColor;
    color: rgba(0, 0, 0, .64);
    -webkit-text-fill-color: rgba(0, 0, 0, .64);
}

.schedule-header {
    padding: 20px;
    display: grid;
    gap: 4px;
    grid-auto-flow: row;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
    justify-items: center;
    width: 100%;
}

.schedule-header .schedule-title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
    font-size: 21px;
}

.schedule-header .schedule-subtitle {
    opacity: .6;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}

.host-select-list-wrapper {
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
}

.host-select-list-wrapper:before {
    content: '';
    left: 0;
    right: 20px;
    top: 0;
    position: absolute;
    background-image: linear-gradient(to bottom, #fff 20%, rgba(255, 255, 255, 0) 100%);
    height: 20px;
    z-index: 1;
}

.host-select-list-wrapper:after {
    content: '';
    left: 0;
    right: 20px;
    bottom: 0;
    position: absolute;
    background-image: linear-gradient(to top, #fff 20%, rgba(255, 255, 255, 0) 100%);
    height: 20px;
    z-index: 1;
}

.host-select-list-wrapper .select-host-container {
    height: 100%;
    display: block;
    overflow-y: auto;
}

.host-select-list-wrapper .select-host-container>* {
    padding-top: 20px;
    padding-bottom: 20px;
}

.select-host-list {
    width: 100%;
    display: grid;
    list-style-type: none;
    margin: 0;
    padding: 0 20px;
}

.no-hosts-message-container {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    justify-items: center;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
}

.select-host-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 44px;
    margin-top: 10px;
}

.select-host-footer .actions-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    gap: 10px;
    justify-items: center;
}

.select-host-footer .actions-container .caazam-button {
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    height: 56px;
    outline: none;
    margin: 0;
    width: 184px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    background-color: black;
    border: none;
    color: #fff;
    -webkit-text-fill-color: #fff;
}

.select-host-footer .actions-container .caazam-button:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.32) !important;
}

.select-host-footer .actions-container .caazam-button.round{
    border-radius: 28px;
}

.select-host-footer .actions-container .secondary-action {
    display: block;
    cursor: pointer;
    color: rgba(0,0,0,.64);
    -webkit-text-fill-color: rgba(0,0,0,.64);
}

.select-host-footer .actions-container .secondary-action.empty {
    cursor: default;
    height: 1.43rem
}

.caazam-mark {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    bottom: 4px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    align-self: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: rgba(0,0,0,.4);
    -webkit-text-fill-color: rgba(0,0,0,.4);
}
