.intro-video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: .15s opacity;
    will-change: opacity;
}

.intro-video-element.visible {
        opacity: 1;
}
