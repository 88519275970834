.dashboard-header {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
    grid-gap: 1rem;
}

.charts-title h2 {
    margin-right: 1em;
}

.charts-title span {
    height: 20px;
}

.custom-time__label {
    margin-top: 6px;
}

/* .dashboard-header > div {
    background: #EDE7F6;
    padding: 1.5rem;
    border-radius: 1rem;
  } */
