.story .card {
    width: 100%;
    height: 100%;
    position: relative;
    --background: #fff;
    --main-color: #6E76F2;
    --background-image: #fff, rgba(0, 107, 175, 0.2);
    --text-color: #666;
    --text-headline: #fff;
    --card-height: 100%;
    --card-width: 100%;
    --card-radius: 12px;
    --header-height: 67px;
    --blend-mode: overlay;
    --transition: 0.15s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.story .card__body-cover {
    --c-border: var(--card-radius) var(--card-radius) 0 0;
    --c-width: 100%;
    --c-height: 100%;
    border-top-left-radius: var(--card-radius);
    border-top-right-radius: var(--card-radius);
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;
    border-bottom: none;
    display: grid;
}

.story .Polaris-SkeletonThumbnail--sizeLarge {
    width: 100%;
    height: 600px;
    border-radius: 12px;
}

.stories-header-search-bar {
    padding: 6px;
}

.twitter-picker span div {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
}
