.link-created-at {
    font-size: 12px;
}

.campaign-input-field {
    margin-bottom: 20px;
}

.campaign-select-rules {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.campaign-image-upload {
    margin-bottom: 20px;
    margin-top: 20px;
}

.campaign-image-upload button {
    margin-top: 20px;
}

.campaign-image-upload .campaign-image-upload_file {
    display: flex;
    flex-direction: row;
}

.caazam-campaign-widget {
    flex-direction: row;
    display: flex;
    flex: 1;
    border-radius: 30px;
    height: 60px;
    max-width: 160px;
    align-items: center;
    transition: width 1s, height 1s;
}

.caazam-campaign-widget svg {
    flex: 1;
}

.caazam-campaign-widget p {
    color: #FFFFFF;
    font-size: 12px;
    flex: 2;
    line-height: 1.4em;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    opacity: 0;
    animation: fadeIn 1s forwards;
    text-align: left;
    width: 92px;
}
