
.pricing-plan {
    background: white;
    border: 1px solid LightGrey;
    border-radius: 1rem;
  }
  .pricing-plan .pricing-header{
    text-align: center;
    vertical-align: middle;
    background-color: #6E76F2;
    color: white;
    height: 30px;
    line-height: 30px;
    font-weight: bold;

  }

  .pricing-plan .pricing-content{
    padding: 1.5rem;
  }

  .pricing-plan .divider{
    width: 200px;
    border-top: 1px solid LightGrey;
}