.caazam-logo {
  width: 158px;
}

.code-snippet {
  font-family: 'Courier New', Courier, monospace;
}

.code-snippet.strong {
  font-weight: bold;
}

.code-background {
  padding: 8px 32px 8px 8px;
  background: rgb(250,251,251);
  border-radius: 8px;
  box-shadow: rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  box-sizing: border-box;
}