.widget-mockup-container {
    font-family: var(--font-family);
    height: 600px;
    width: 440px;
    box-shadow: inset 0 -4px 0 0 currentColor;
    color: rgba(0,0,0,.64);
    -webkit-text-fill-color: rgba(0,0,0,.64);
    background-color: #fff;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    --filledBtnTextColor: #fff;
}

.widget-mockup-container .video-room-container {
    width: 100%;
    height: 320px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-mockup-container .video-room-container .Polaris-Icon {
    height: 8em;
    width: 8em;
}

.widget-mockup-container .form-container {
    flex-grow: 1;
    margin-top: 20px;
    width: 75%;
}

.widget-mockup-container .form-container .form {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto 1fr;
    grid-row-gap: 16px;
    height: 100%;
}

.widget-mockup-container .form-container .form h3 {
    font-size: 1.33em;
    line-height: 1.33em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 10px 0 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.64);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
}

.widget-mockup-container .form-container .form .footer {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.32);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.32);
    margin-bottom: 20px;
    align-self: flex-end;
    text-align: center;
}

.widget-mockup-container .form-container .form .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.widget-mockup-container .form-container .form .actions-container .schedule {
    all: unset;
    margin-left: 1ch;
    text-align: center;
    font-family: var(--font-family);
}

.widget-mockup-container .form-container .form .actions-container .caazam-button {
    font-family: var(--font-family);
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    height: 56px;
    outline: none;
    margin: 0;
    width: 184px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    background-color: black;
    border: none;
    color: var(--filledBtnTextColor);
    -webkit-text-fill-color: var(--filledBtnTextColor);
}

.widget-mockup-container .form-container .form .actions-container .caazam-button.lowercase {
    text-transform: lowercase;
}

.widget-mockup-container .form-container .form .actions-container .caazam-button.capitalize {
    text-transform: capitalize;
}

.widget-mockup-container .form-container .form .actions-container .caazam-button.round{
    border-radius: 28px;
}

.caazam-input {
    font-family: var(--font-family);
	border-radius: 4px;
	height: 56px;
	border: 1px solid rgba(0, 0, 0, 0.32);
	outline: none;
	color: rgba(0, 0, 0, 0.64);
	-webkit-text-fill-color: rgba(0, 0, 0, 0.64);
	padding: 16px;
	width: 100%;
	box-sizing: border-box;
    text-align: start;
    font-size: 16px;
}

.caazam-input.round {
	border-radius: 32px;
}

.caazam-input:focus {
    border: 1px solid currentColor;
    box-shadow: none;
}

.caazam-input::placeholder {
    color: rgba(0, 0, 0, 0.32);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.32);
}

.sharp {
    border-radius: 8px;
}

.round {
    border-radius: 32px;
}


.twitter-picker {
    width: auto !important;
    max-width: 276px !important;
}
