.shop-open-block_label {
    padding: 8px 16px 4px 16px;
    background-color: #6E76F2;
    border-radius: 4px;
    height: 36px;
}

.shop-open-block_label p {
    color: #FFFFFF;
    font-weight: bold;
    font-family: "Jost",sans-serif;
    text-align: center;
}

.shop-open-block_options {
    margin-left: 10px;
}
