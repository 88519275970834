.intro-welcome-wrapper {
    display: flex;
    flex-flow: column;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.intro-welcome-wrapper .welcome-background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
