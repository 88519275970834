.intro-video-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    background: black;
}

.intro-video-wrapper .progressbar-container {
    position: absolute;
    top: 18px;
    left: 12px;
    right: 48px;
    display: flex;
    flex-flow: row nowrap;
}
