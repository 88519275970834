
.query-param-block-label {
    padding: 8px 16px 4px 16px;
    background-color: #6E76F2;
    border-radius: 4px;
    height: 36px;
}

.query-param-block-label p {
    color: #FFFFFF;
    font-weight: bold;
    font-family: "Jost",sans-serif;
    text-align: center;
}

.query-param-block-close {
    justify-content: center;
    display: flex;
    cursor: pointer;
}
