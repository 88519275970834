.list-item-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    padding-left: 1em;
    padding-bottom: .5em;
    padding-top: 0.5em;
    position: relative;
    cursor: pointer;
    min-height: 4em;
}

.list-item-wrapper .selected-tag {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.list-item-wrapper.disabled {
    pointer-events: none;
}

.list-item-wrapper.disabled .list-item {
    opacity: .4;
}

.list-item-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid var(--p-action-secondary-depressed);
    opacity: 0;
    will-change: opacity;
    transition: .2s opacity;
    background: rgba(0, 0, 0, 0.08);
    right: 0;
}

.list-item-wrapper:not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--p-divider);
}

.list-item-wrapper.active:before {
    opacity: 1;
}

.list-item-wrapper .list-item {
    margin-left: 1em;
    z-index: 1;
    grid-column: 2;
    align-self: center;
}

.list-item-wrapper .list-item .label {
    font-weight: 500;
    z-index: 1;
    font-size: calc(1em + 2px);
}

.list-item-wrapper .list-item .subline {
    z-index: 1;
    margin-top: 8px;
}
