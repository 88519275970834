.thumbnail-image-container {
    width: 60px;
    height: 114px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.thumbnail-image-container.disabled .thumbnail-image {
    filter: grayscale(100%);
}

.thumbnail-image-container.disabled .upload-disabled-container {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

}

.thumbnail-image-container .thumbnail-image {
    width: 100%;
    height: auto;
    will-change: opacity;
    animation: showThumbnail .4s forwards;
}

@keyframes showThumbnail {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
