.product-preview .Polaris-Thumbnail {
    border: var(--p-border-divider) !important;
    margin: 0 4px;
}

.product-preview .Polaris-Thumbnail--transparent {
    border: 0 !important;
}

.product-preview.collapsed .Polaris-Thumbnail {
    height: calc(var(--image-height) - 10px);
    width: calc(var(--image-width) - 10px);
}

.product-preview.expanded .Polaris-Thumbnail {
    height: var(--image-height);
    width: var(--image-width);
}

.product-preview.collapsed:hover .Polaris-Thumbnail {
    height: var(--image-height);
    width: var(--image-width);
}

.product-preview {
    --image-height: 80px;
    --image-width: 80px;
    position: absolute;
    background-color: var(--p-surface);
    box-shadow: var(--p-shadow-card);
    outline: var(--p-border-width-1) solid transparent;
    border-radius: 0.5rem;
    padding: 6px 6px 6px 0;
    z-index: 1;
    max-height: calc(var(--image-height) + 12px);
}
.product-preview.collapsed {
    width: calc(var(--image-width) + 10px);
    align-items: center;
    display: flex;
    flex-flow: column;
    transition: width .15s, padding-bottom .1s;
    padding: 6px 0 0;
}

.product-preview.collapsed.hidden {
    display: none;
}

.product-preview.collapsed:hover {
    width: calc(100% - 50px);
    flex-flow: row;
    align-items: flex-start;
    opacity: 1;
    padding: 6px 6px 6px 0;
    z-index: 100;
}

.product-preview.expanded {
    width: calc(100% - 50px);
    display: flex;
    flex-flow: row;
}

.product-preview.expanded.hidden {
    display: none;
}

.story-product-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-preview-price-container.small-text .Polaris-Text--bodySm {
    font-size: 9px;
}

.product-preview-description-container.action .Polaris-Text--headingSm {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.product-preview-price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-preview-description-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    max-height: 90px;
}

.product-preview-description-container.action {
    grid-template-columns: auto 90px;
}

.product-preview-action-button {
    min-height: 1.75rem;
    padding: 6px;
    position: relative;
    background-color: var(--p-action-primary);
    box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 2.25rem;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    text-align: center;
    border-radius: 0.25rem;
    border: 0.06rem solid rgb(186, 191, 195);
    user-select: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

